/* global $ */
import "../../css/app/components/home.scss";
class Home
{
    constructor () 
    {
        this.slideShow();
    }

    slideShow() {
        if ($('.banner-slide').length > 1) {
            setInterval(function() {
                let current = $('.banner-slide.show');
                let next = current.next().length ? current.next() : current.siblings().first();
                current.fadeOut(2000).removeClass('show');
                next.fadeIn(2000).addClass('show');
            }, 10000);
        }
    }
}

new Home;